import { 
  Grid, 
  Paper, 
  Select, 
  TextField,
  styled,
} from "@mui/material";


const ParentGrid = styled(Grid)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%', 
  width: '100%',
}));

const FileListGrid = styled(Grid)(({theme}) => ({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  padding: '1rem',
  paddingRight: '0',
}));

const DocumentListGrid = styled(Grid)(({theme}) => ({
  flex: '3',
  display: 'flex',
  width: '100%',
  height: '90dvh',
  padding: '1rem'
}));

const InnerGridBackgroundContainer = styled(Paper)(({theme}) => ({
  width: '100%', 
  height: '100%', 
  // backgroundColor: '#1E1E1E', 
  border: '1px solid #eef2fc',
  borderRadius: '1rem',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

const CodeBlockContainer = styled(Grid)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '100%', // Adjust as needed
  overflow: 'auto',
  borderRadius: '5px',
  // padding: '10px',
  backgroundColor: '#2d2d2d', // Match the theme background,
  [theme.breakpoints.down('md')]: {
    maxWidth: '75dvw'
  }
}));

const CustomTextField = styled(TextField)(({theme}) => ({
  margin: '0',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `${theme.palette.grays.main}`,
      borderRadius: '1rem',
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.grays.main}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.grays.main}`,
    },
    color: `${theme.palette.grays.main}`,
  },
  '& .MuiInputLabel-root': {
    color: `gray!important`,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: `gray!important`,
  },
  '& .MuiOutlinedInput-input': {
    color: 'gray!important',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'gray!important',
  },
  '& .MuiOutlinedInput-input::placeholder': {
    color: 'gray!important',
  },
}));

const FolderSelect = styled(Select)(({ theme }) => ({
  color: 'gray!important', 
  flex: '1',
  display: 'flex', 
  width: '100%',
  height: '100%',
  borderRadius:  '1rem',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `${theme.palette.grays.main}!important`,
      borderRadius: '1.5rem',
      color: 'gray!important'
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.grays.main}!important`,
      color: 'gray!important'
    },
    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.grays.main}!important`,
      color: 'gray!important'
    },
    color: `${theme.palette.grays.main}!important`,
  },
  '& .MuiInputLabel-root': {
    color: 'gray!important',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'gray!important',
  },
  '& .MuiOutlinedInput-input': {
    color: 'gray!important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'gray!important',
  },
  '& .MuiOutlinedInput-notchedOutline::placeholder': {
    color: 'gray!important',
  },
  '& .MuiSelect-icon': {
    color: 'gray!important',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: 'gray!important'
  }
}));


export {
  ParentGrid,
  FileListGrid,
  DocumentListGrid,
  InnerGridBackgroundContainer,
  CodeBlockContainer,
  CustomTextField,
  FolderSelect,
}