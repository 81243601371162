import React from 'react'
import { 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  IconButton, 
  styled, 
  useMediaQuery 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    maxWidth: '90dvw',
  },
}));

function VideoDemo({ startVideo, setStartVideo }) {

	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const desktopVideoDemo = 'https://res.cloudinary.com/djrbfvpit/video/upload/v1722272714/DesktopDemoGPTOrganized_sjpfwd.mp4';
  const mobileVideoDemo = 'https://res.cloudinary.com/djrbfvpit/video/upload/v1722273338/MobileDemoGptOrganized_pijvbi.mp4';

  const handleClose = () => {
    setStartVideo(false);
  };

  return (
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={startVideo}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Demo Video
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {
            isMobile ? (
              <video controls width="275rem" height="500rem">
                <source src="/media/cc0-videos/flower.webm" type="video/webm" />
                <source src={mobileVideoDemo} type="video/mp4" />
              </video>
            ) : (
              <video controls width="600rem" height="350rem">
                <source src="/media/cc0-videos/flower.webm" type="video/webm" />
                <source src={desktopVideoDemo} type="video/mp4" />
              </video>
            )
          }
        </DialogContent>
      </BootstrapDialog>
  )
}

export default VideoDemo;