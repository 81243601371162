import React, 
{ 
  useEffect, 
  useMemo, 
  useState 
} from 'react';
import { 
  ParentGrid 
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import FilesViewDesktop from './FilesViewDesktop';
import FilesViewMobile from './FilesViewMobile';
import { CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { deleteGPTFolder, deleteGPTMessage } from '../../actions/gptChatActions';

function DocumentManager() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { isAuthenticated } = useAuth0();

  const ownedMessages = useSelector((state) => state.gptChat.ownedDbMessages);
  const externalMessages = useSelector((state) => state.gptChat.externalDbMessages);

  const dbMessagesLoading = useSelector((state) => state?.gptChat?.dbMessagesLoading);
  const trialUserSavedGPTMessages = useSelector((state) => state.gptChat.trialMessageSaves);
  const userId = useSelector((state) => state.userData.loginInfo.user_id);

  const [selectedFolder, setSelectedFolder] = useState('All');
  const [filteredDocs, setFilteredDocs] = useState(null);
  const [managerView, setManagerView] = useState('Folders');
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [savedFiles, setSavedFiles] = useState(null);
  
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const checkOwnedAndExternalMessagesAndReturnArray = () => {
    if(ownedMessages && externalMessages){
      return [...ownedMessages, ...externalMessages];
    };

    if(!ownedMessages && externalMessages){
      return [...externalMessages];
    };

    if(ownedMessages && !externalMessages){
      return [...ownedMessages];
    };
  };

  const reduceSharedMessageFolders = () => {
    if(isAuthenticated){
      return externalMessages?.reduce((acc, item) => {
        if (!acc.includes(item.folder)) {
          acc.push(item.folder);
        }
        return acc;
      }, []);
    };
  };

  const reduceOwnedMessageFolders = () => {
    if(isAuthenticated){
      return ownedMessages?.reduce((acc, item) => {
        if (!acc.includes(item.folder)) {
          acc.push(item.folder);
        }

        return acc;
      }, []);
    };
  };

  const getUniqueFolders = () => {

    if(isAuthenticated && ownedMessages && externalMessages){
      return checkOwnedAndExternalMessagesAndReturnArray()?.reduce((acc, item) => {
        if (!acc.includes(item.folder)) {
          acc.push(item.folder);
        }
        return acc;
      }, []);
    };

    return trialUserSavedGPTMessages?.reduce((acc, item) => {
      if (!acc.includes(item.folder)) {
        acc.push(item.folder);
      }
      return acc;
    }, []);
  };

  const uniqueFolders        = getUniqueFolders();
  const sharedMessageFolders = reduceSharedMessageFolders();
  const ownedMessageFolders  = reduceOwnedMessageFolders();

  const handleDeleteFolder = () => {
    dispatch(deleteGPTFolder(userId, selectedFolder, dispatch));
    setSelectedFolder('All');
  };

  const handleDeleteFile = () => {
    dispatch(deleteGPTMessage(userId, selectedFileData._id, dispatch));
    navigate('/folders');
    setManagerView('Files');
  };

  useMemo(() => {
    setSavedFiles(checkOwnedAndExternalMessagesAndReturnArray());

    // eslint-disable-next-line
  }, [ownedMessages, externalMessages])

  useEffect(() => {
    if(selectedFolder !== 'All' && isAuthenticated){
      setFilteredDocs(
        savedFiles?.filter(
          (message) => message?.folder === selectedFolder
        )
      );
      return;
    };

    if(isAuthenticated && selectedFolder === 'All'){
      setFilteredDocs(checkOwnedAndExternalMessagesAndReturnArray());
      return;
    };

    if(selectedFolder !== 'All' && !isAuthenticated){
      setFilteredDocs(
        trialUserSavedGPTMessages?.filter(
          (message) => message.folder === selectedFolder
        )
      );
      return;
    }  

    if(selectedFolder === 'All' && !isAuthenticated){
      setFilteredDocs(trialUserSavedGPTMessages);
      return;
    }
    //eslint-disable-next-line
  }, [selectedFolder, ownedMessages, externalMessages]);

  useEffect(() => {
    if (params?.id) {
      const _id = params.id;
      const updatedFileData = [
        ...(ownedMessages ?? []), 
        ...(externalMessages ?? [])
      ].find((msg) => msg._id === _id);
  
      setSelectedFileData(updatedFileData || null);
      setManagerView('Document');
      setSelectedFolder(updatedFileData?.folder);
    };
  
    //eslint-disable-next-line
  }, [ownedMessages, externalMessages]);

  return (
    <ParentGrid container>
      {
        dbMessagesLoading ? (
          <Grid 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              flexDirection: 'column',
              alignItems: 'center', 
              width: '-webkit-fill-available', 
              height: '-webkit-fill-available'
            }}
          >
            <Typography variant='h5' sx={{color: 'white', marginBottom: '1rem'}}>
              Loading Messages...
            </Typography>
            <CircularProgress sx={{width: '3rem', height: '3rem', color: '#84A8FF'}}/>
          </Grid>
        ) : (
          
          isMobile ? (
            <FilesViewMobile
              selectedFolder        = { selectedFolder       }
              setSelectedFolder     = { setSelectedFolder    }
              filteredDocs          = { filteredDocs         }
              uniqueFolders         = { uniqueFolders        }
              ownedMessageFolders   = { ownedMessageFolders  }
              sharedMessageFolders  = { sharedMessageFolders } 
              managerView           = { managerView          }
              selectedFileData      = { selectedFileData     }
              setSelectedFileData   = { setSelectedFileData  }
              setManagerView        = { setManagerView       }
              handleDeleteFolder    = { handleDeleteFolder   }
              handleDeleteFile      = { handleDeleteFile     }
            />
          ) : (
            <FilesViewDesktop
              selectedFolder        = { selectedFolder       }
              setSelectedFolder     = { setSelectedFolder    }
              filteredDocs          = { filteredDocs         }
              uniqueFolders         = { uniqueFolders        }
              ownedMessageFolders   = { ownedMessageFolders  }  
              sharedMessageFolders  = { sharedMessageFolders }
              managerView           = { managerView          }
              selectedFileData      = { selectedFileData     }
              setSelectedFileData   = { setSelectedFileData  }
              setManagerView        = { setManagerView       }
              handleDeleteFolder    = { handleDeleteFolder   }
              handleDeleteFile      = { handleDeleteFile     }
            />
          )
        )
      }
    </ParentGrid>
  )
}

export default DocumentManager;