import React from 'react';
import ContentArea from '../../components/ContentArea';
import { DrawerHeader, Main } from '../../styled';
import FAQs from '../../containers/FAQs';


function FAQPage({ drawerOpen, drawerWidth }) {
  
  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<FAQs />} />
    </Main>
  );
}

export default FAQPage;