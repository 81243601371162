import React from 'react'
import DocumentManager from '../../containers/DocumentManager';
import ContentArea from '../../components/ContentArea';
import { DrawerHeader, Main } from '../../styled';

function FoldersTrial({ drawerOpen, drawerWidth }) {
  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<DocumentManager />} />
    </Main>
  );
}

export default FoldersTrial;