import { 
  Box, 
  Grid, 
  List, 
  ListItem, 
  styled
} from '@mui/material';

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(2)
}));

const ChatHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2)
}));

const ChatBody = styled(List)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  marginBottom: theme.spacing(2),
  paddingRight: theme.spacing(1),
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
}));

const ChatBubbleMenuContainer = styled(Grid)(({theme, isCurrentUser, userIsOwner }) => ({
  display: 'flex', 
  flexDirection: isCurrentUser ? 'row-reverse' : 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  '&:hover #hoverGrid': {
    display: (isCurrentUser || userIsOwner) && 'block'
  }
}));

const MenuIconContainer = styled(Grid)(({ theme, isCurrentUser }) => ({
  display: 'none', 
  marginLeft: !isCurrentUser && '0.5rem',
  marginRight: isCurrentUser && '0.5rem'
}));

const ChatBubble = styled(ListItem)(({ theme, isCurrentUser }) => ({
  backgroundColor: isCurrentUser ? theme.palette.primary.lt : theme.palette.grey[300],
  color: theme.palette.text.primary,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
  maxWidth: '85%',
  flexDirection: isCurrentUser ? 'row-reverse' : 'row'
}));

const ChatInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export {
  ChatContainer,
  ChatHeader,
  ChatBody,
  ChatBubble,
  ChatInputContainer,
  ChatBubbleMenuContainer,
  MenuIconContainer
}
