import React, { useEffect, useMemo, useState } from 'react'
import { 
  Button, 
  Grid, 
  IconButton, 
  ListItem, 
  Tooltip, 
  Typography 
} from '@mui/material';
import { 
  DocumentListGrid, 
  FileListGrid, 
  InnerGridBackgroundContainer 
} from './styled';
import FolderListItem from '../../components/FolderListItem';
import DocumentListItem from '../../components/DocumentListItem';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { retrieveCollaboratorUsersInfo } from '../../actions/collaboratorActions';
import { updateGptMessage } from '../../actions/gptChatActions';
import { 
  MESSAGES_COLLECTION_SAVE_SUCCESS, 
  UPDATE_TRIAL_GPT_MESSAGE_SUCCESS 
} from '../../consts/gptChatConstants';
import { augmentSharedWithCollaboratorInfo } from './helpers';
import DocDetailView from './DocDetailView';

function FilesViewMobile({
  selectedFolder,
  setSelectedFolder,
  filteredDocs,
  uniqueFolders,
  ownedMessageFolders,
  sharedMessageFolders,
  managerView,
  selectedFileData,
  setSelectedFileData,
  setManagerView,
  handleDeleteFile,
  handleDeleteFolder
}) {

  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId = useSelector((state) => state?.userData?.loginInfo?.user_id);
  const allUsersCollaborators = useSelector((state) => state?.collaborators?.retrievedCollaborators);

  const [editDoc, setEditDoc] = useState(false);
  const [documentContent, setDocumentContent] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [documentFolder, setDocumentFolder] = useState(null);
  const [documentChatHistory, setDocumentChatHistory] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [collaboratorsOpen, setCollaboratorsOpen] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [docCollaboratorChats, setDocCollaboratorChats] = useState([]);

  const collaboratorsUserInfo = () => {
    const collaboratorsIds = selectedFileData?.sharedWith?.map(
      (user) => user.user_id
    );

    const filterUnfetchedCollaborators = (ids, allUsers) => {
      return ids.filter(id => !allUsers.some(user => user.user_id === id));
    };

    const missingCollaboratorIds = filterUnfetchedCollaborators(
      collaboratorsIds, 
      allUsersCollaborators
    );

    if(missingCollaboratorIds.length){
      dispatch(retrieveCollaboratorUsersInfo(missingCollaboratorIds, dispatch));
    };
  };

  const handleSave = (updatedCollaborators) => {
    setCollaborators(updatedCollaborators);
  };

  const handleContinueConversation = () => {
    dispatch({ 
      type: MESSAGES_COLLECTION_SAVE_SUCCESS, 
      payload: selectedFileData?.savedMessageHistory
    });

    if(isAuthenticated){
      navigate('/app-home');
    } else {
      navigate('/');
    };
  };
  
  const handleUpdateContent = async() => {

    if(isAuthenticated){
    
      dispatch(
        updateGptMessage(
          {
            content: documentContent, 
            folder: documentFolder, 
            docName: documentName, 
            messageId: selectedFileData?._id
          }
        )
      );

      setEditDoc(false);
       
    } else {
      dispatch({
        type: UPDATE_TRIAL_GPT_MESSAGE_SUCCESS,
        payload: {
          content: documentContent,
          folder: documentFolder,
          docName: documentName,
          savedMessageHistory: documentChatHistory,
          _id: documentId
        }
      });

      setEditDoc(false)
    };
  };
  
  const userIsOwner = () => {
    if(selectedFileData?.ownerId !== userId){
      return false;
    };

    return true;
  };

  const userCanEdit = () => {
    const userInMessageObject = selectedFileData?.sharedWith?.filter((user) => user?.user_id === userId);
    if(userInMessageObject){
      const userPermission = userInMessageObject[0]?.permission;

      if(userPermission === 'canEdit'){
        return true;
      };
    };

    return false;
  };

  useMemo(() => {
    if(selectedFileData){
      setDocumentContent(selectedFileData?.content);
      setDocumentName(selectedFileData?.docName);
      setDocumentFolder(selectedFileData?.folder);
      setDocumentChatHistory(selectedFileData?.savedMessageHistory);
      setDocumentId(selectedFileData?._id);
      setDocCollaboratorChats(selectedFileData?.collabChats);
    };

  }, [selectedFileData]);

  // Whenever new collaborators are added,
  // this adds enriched user data for display from the
  // collaboratorsUserInfo() method result
  useMemo(() => {

    if(allUsersCollaborators && selectedFileData?.sharedWith){

      const updatedFileData = augmentSharedWithCollaboratorInfo(selectedFileData, allUsersCollaborators);
      setCollaborators(updatedFileData?.sharedWith);
    };

  }, [allUsersCollaborators, selectedFileData]);

  // Gets any collaborators full userInfo from database for 
  // proper display of data about that user
  useEffect(() => {
    if(selectedFileData?.sharedWith?.length){
      collaboratorsUserInfo();
    };

    // eslint-disable-next-line
  }, [selectedFileData?.sharedWith]);

  const truncateText = (text) => {
    const maxLength = 13;
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  const handleManagerView = () => {
    switch(managerView){
      case 'Folders':
        return (
          <FileListGrid container sx={{paddingRight: '1rem'}}>
            <InnerGridBackgroundContainer>
              {
                !isAuthenticated && (
                  <ListItem sx={{display: 'flex', justifyContent: 'center'}}>
                    <Button 
                      variant='contained' 
                      color='primary'
                      onClick={() => loginWithRedirect()}
                    >
                      <Typography sx={{color: 'white'}}>
                        <b>Login</b> To Keep Saved Files
                      </Typography>
                    </Button>
                  </ListItem>
                )
              }
              <FolderListItem
                folder={'All'}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder} 
                setManagerView={setManagerView}
              />
              {
                ownedMessageFolders?.map((folder, index) => (
                  <FolderListItem 
                    key={index}
                    folder={folder}
                    selectedFolder={selectedFolder}
                    shared={false}
                    setSelectedFolder={setSelectedFolder}
                    setManagerView={setManagerView}
                  />
                ))
              }
              {
                sharedMessageFolders?.map((folder, index) => (
                  <FolderListItem 
                    key={index}
                    folder={folder}
                    selectedFolder={selectedFolder}
                    shared={true}
                    setSelectedFolder={setSelectedFolder}
                    setManagerView={setManagerView}
                  />
                ))
              }
            </InnerGridBackgroundContainer>
          </FileListGrid>
        )
      case 'Files':
        return (
          <DocumentListGrid container>
            <InnerGridBackgroundContainer>
              <Grid 
                sx={{
                  display: 'flex', 
                  alignItems: 'center', 
                  flex: '1', 
                  width: '100%', 
                  justifyContent: 'space-between',
                  maxHeight: '5rem'
                }}
              >
                <IconButton 
                  sx={{
                    marginTop: '0.5rem', 
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    backgroundColor: 'lightgray'
                  }}
                  onClick={() => setManagerView('Folders')}
                >
                  <ArrowBackOutlinedIcon  
                    sx={{
                      color: 'white', 
                      fontSize: '2rem',
                    }} 
                  />
                </IconButton>
                <Tooltip title={selectedFolder}>
                  <Typography
                    variant='h4' 
                    sx={{
                      paddingRight: '1rem', 
                      color: 'gray',
                      paddingTop: '0.5rem'
                    }}
                  >
                    {truncateText(selectedFolder)}
                  </Typography>
                </Tooltip>
              </Grid>
              {
                filteredDocs?.map((message) => (
                  <DocumentListItem 
                    messageData={message} 
                    setSelectedFileData={setSelectedFileData}
                    setManagerView={setManagerView}
                    isMobile={true}
                  />
                ))
              }
              {
                selectedFolder !== 'All' && (
                  <Button 
                    variant='contained'
                    sx={{
                      backgroundColor: 'red',
                      color: 'white',
                      width: '90%',
                      marginLeft: '5%',
                      marginTop: '1rem',
                      marginBottom: '1rem',
                      fontSize: '1rem',
                    }}
                    onClick={handleDeleteFolder}
                  >
                    Delete Folder
                  </Button>
                )
              }
            </InnerGridBackgroundContainer>
          </DocumentListGrid>
        )
      case 'Document':
        return (
          <DocDetailView 
            setManagerView={setManagerView}
            setEditDoc={setEditDoc}
            navigate={navigate}
            editDoc={editDoc}
            documentFolder={documentFolder}
            setDocumentFolder={setDocumentFolder}
            uniqueFolders={uniqueFolders}
            userIsOwner={userIsOwner}
            userCanEdit={userCanEdit}
            documentName={documentName}
            setDocumentName={setDocumentName}
            setCollaboratorsOpen={setCollaboratorsOpen}
            collaborators={collaborators}
            collaboratorsOpen={collaboratorsOpen}
            handleSave={handleSave}
            selectedFileData={selectedFileData}
            handleUpdateContent={handleUpdateContent}
            handleContinueConversation={handleContinueConversation}
            handleDeleteFile={handleDeleteFile}
            documentContent={documentContent}
            setDocumentContent={setDocumentContent}
            isAuthenticated={isAuthenticated}
            docCollaboratorChats={docCollaboratorChats}
            documentId={selectedFileData?._id}
          />
        )
      default: return (
        <FileListGrid container>
          <InnerGridBackgroundContainer>
            <FolderListItem
              folder={'All'}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder} 
            />
            {
              uniqueFolders.map((folder) => (
                <FolderListItem 
                  folder={folder}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder} 
                />
              ))
            }
          </InnerGridBackgroundContainer>
        </FileListGrid>
      )
    }
  }

  return (
    <>
      {
        handleManagerView()
      }
    </>
  )
}

export default FilesViewMobile;