import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { 
  AvatarContainer, 
  CustomTextField, 
  InformationCard, 
  MainContentArea,   
  MainContentAreaChildGrid,   
  TabsAndPanelsParentBox 
} from './styled';
import { 
  Avatar, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Grid, 
  Typography 
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { deleteUser, editUsernameEmail } from '../../actions/userActions';
import getUserFilesNumber from '../../helpers/getUniqueFolders';
import { useAuth0 } from '@auth0/auth0-react';
import useCurrentUserInfo from '../../utils/useCurrentUserInfo';


function UserProfile() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const userInfo = useCurrentUserInfo();
  
  const userSavedMessages = useSelector((state) => state?.userData?.loginInfo?.gptMessages);
  const userFileLimit = useSelector((state) => state?.userData?.loginInfo?.userFileLimit);

  const [userEmail, setUserEmail] = useState(userInfo?.email);
  const [username, setUsername] = useState(userInfo?.username);
  const [deleteProfleOpen, setDeleteProfileOpen] = useState(false);

  const handleClose = () => {
    setDeleteProfileOpen(false);
  };

  const handleUpdate = () => {
    dispatch(
      editUsernameEmail({ 
        userId: userInfo?.user_id, 
        username: username, 
        email: userEmail
      }, dispatch)
    );
  };

  const handleDeleteProfile = () => {
    dispatch(deleteUser(userInfo?.user_id, dispatch));
    logout();
  };
  
  return (
    <MainContentArea elevation={0}>
      <MainContentAreaChildGrid container >
        <AvatarContainer container>
          <Avatar sx={{margin: '1rem'}} alt={userInfo?.name} src={userInfo?.picture} />
          <Typography sx={{color: 'gray'}}>{userInfo?.name}</Typography>
        </AvatarContainer>
        <TabsAndPanelsParentBox>
          <InformationCard elevation={5}>
            <Grid 
              sx={{display: 'flex', flexDirection: 'column', flex: '2', height: 'fit-content', width: '100%'}}>
              <CustomTextField
                name="username"
                value={username ?? null}
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => setUsername(e.target.value)}
              />
              <CustomTextField
                disabled={userInfo?.email}
                name="email"
                value={userEmail ?? null}
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </Grid>
            <Grid 
              sx={{
                display: 'flex', 
                width: '100%', 
                height: '100%', 
                flex: '1', 
                justifyContent: 'center', 
                alignItems: 'center'
              }}
            >
              <Button 
                variant='contained'
                sx={{
                  width: '95%', 
                  margin: '1rem', 
                  marginBottom: '0',
                  '&.Mui-disabled': {
                    backgroundColor: 'lightgray!important',
                    background: 'transparent'
                  },
                  background: 'linear-gradient(to left, #507CE6 0%, #84A8FF 100%)',
                  color: 'white'
                }}
                disabled={
                  (userInfo?.email === userEmail) && 
                  (userInfo?.username === username)
                }
                onClick={() => handleUpdate()}
              >
                Save
              </Button>
            </Grid>
            <Grid 
              sx={{
                display: 'flex', 
                width: '100%', 
                height: '100%', 
                flex: '1', 
                justifyContent: 'center', 
                alignItems: 'center'
              }}
            >
              <Button 
                variant='outlined'
                sx={{
                  width: '95%', 
                  margin: '1rem', 
                  borderColor: 'red',
                  color: 'red'
                }}
                onClick={() => setDeleteProfileOpen(true)}
              >
                Delete Profile
              </Button>
            </Grid>
          </InformationCard>
          <InformationCard>
            <Grid 
              container 
              sx={{
                display: 'flex', 
                flexDirection: 'column', 
                width: '100%', 
                height: '100%', 
                justifyContent: 'space-around', 
                alignItems: 'center', 
                overflow: 'visible'
              }}
            >
              <Grid 
                item 
                sx={{
                  flex: '1', 
                  display: 'flex', 
                  width: '100%', 
                  alignItems: 'flex-start', 
                  padding: '1rem'
                }}
              >
                <Typography variant='h5' sx={{color: 'gray'}}>Your Subscription</Typography>
              </Grid>
              <Grid 
                container 
                sx={{
                  flex: '1', 
                  border: '1px solid #C6C6C6', 
                  borderRadius: '1rem', 
                  padding: '2rem 0', 
                  width: '95%'
                }}
              >
                <Grid 
                  sx={{
                    marginLeft: '1rem', 
                    display: 'flex', 
                    justifyContent: 'space-around', 
                    flexDirection: 'column', 
                    height: '100%'
                  }}
                >
                  <Typography 
                    variant='h6' 
                    sx={{color: 'gray'}}
                  >
                    Plan - <span style={{color: `${theme.palette.grays.main}`}}>Free</span>
                  </Typography>
                  <Typography 
                    variant='h6' 
                    sx={{color: 'gray'}}
                  >
                    AI Credits Monthly - <span style={{color: `${theme.palette.grays.main}`}}>100</span>
                  </Typography>
                  <Typography 
                    variant='h6' 
                    sx={{color: 'gray'}}
                  >
                    Total Folders - <span style={{color: `${theme.palette.grays.main}`}}>{userFileLimit ?? 5}</span>
                  </Typography>
                  {/* <Typography 
                    variant='h6' 
                    sx={{color: 'white'}}
                  >
                    Next Bill Date - <span style={{color: `${theme.palette.secondary.main}`}}>09/14/2024</span>
                  </Typography> */}
                </Grid>
              </Grid>
              <Grid item sx={{flex: '1', width: '100%', padding: '1rem'}}>
                <Button variant='contained' color='secondary' sx={{width: '100%'}}>
                  Paid Options Coming Soon!
                </Button>
              </Grid>
            </Grid>
          </InformationCard>
          <InformationCard>
            <Grid 
              container 
              sx={{
                display: 'flex', 
                flexDirection: 'column', 
                width: '100%', 
                height: '100%', 
                justifyContent: 'space-around', 
                alignItems: 'center', 
                overflow: 'visible'
              }}
            >
              <Grid 
                item 
                sx={{
                  flex: '1', 
                  display: 'flex', 
                  width: '100%', 
                  alignItems: 'flex-start', 
                  padding: '1rem'
                }}
              >
                <Typography variant='h5' sx={{color: 'gray'}}>AI Wallet</Typography>
              </Grid>
              <Grid 
                container 
                sx={{
                  flex: '1', 
                  border: '1px solid #C6C6C6', 
                  borderRadius: '1rem', 
                  padding: '2rem 0', 
                  width: '95%'
                }}
              >
                <Grid 
                  sx={{
                    marginLeft: '1rem', 
                    display: 'flex', 
                    justifyContent: 'space-around', 
                    flexDirection: 'column', 
                    height: '100%'
                  }}
                >
                  <Typography 
                    variant='h6' 
                    sx={{color: 'gray'}}
                  >
                    AI Credits - <span style={{color: `${theme.palette.grays.main}`}}>{userInfo?.userCredits}</span>
                  </Typography>
                  <Typography 
                    variant='h6' 
                    sx={{color: 'gray'}}
                  >
                    Folders Used - <span style={{color: `${theme.palette.grays.main}`}}>{getUserFilesNumber(userSavedMessages)} / {userFileLimit ?? 5}</span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sx={{flex: '1', width: '100%', padding: '1rem'}}>
                <Button variant='contained' color='secondary' sx={{width: '100%'}}>
                  Add Credits Coming Soon!
                </Button>
              </Grid>
            </Grid>
          </InformationCard>
        </TabsAndPanelsParentBox>
      </MainContentAreaChildGrid>
      <Dialog open={deleteProfleOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <p>Are you sure you wish to delete your profile? This is unreversable.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant='contained'> 
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteProfile} 
            autoFocus 
            sx={{color: 'red', borderColor: 'red'}} 
            variant='outlined'
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </MainContentArea>
  );
}

export default UserProfile;