export const faqItems = [
  {
    title: 'Saving - How Do I Save An AI Response?',
    description: "Every time the A.I. responds to a message you type in, there is a 'Save' button at the top of the response. Click on that to see a pop-out where you are able to name the file, add it to a folder and even create a new custom folder to store it in.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724949021/GPTOrg%20Assets/SaveMessageDemo_krzmyq.gif'
  },
  {
    title: 'Saving - Where Do I See My Saved Responses?',
    description: "In the navigation sidebar there is a link called 'Folders' where you'll be directed to your collection of saved messages and can easily sort them, view them, then edit them.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724952744/GPTOrg%20Assets/ViewFolders_ecsopz.gif'
  },
  {
    title: 'Editing - How Do I Edit A Saved Response? (Desktop Only)',
    description: "To edit a saved response click on the 'Folders' link in the sidebar, click on the folder you wish to view or view all files by default, click on the message arrow to view the message and then in the top right of the message header (desktop only) you'll see an icon to 'Edit' the file. Make your edits then be sure to click the 'Save' icon in the top right.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724952906/GPTOrg%20Assets/EditFileDemo_1_dbaj9i.gif'
  },
  {
    title: 'Editing - Changing File Names & Folders  (Desktop Only)',
    description: "After you've navigated to the 'Folders' page and clicked on the document arrow to view the document, you can click on the 'Edit' icon to open the editing view. Here in the top of the page you'll see a text field and dropdown allowing for renaming of the file and for moving of the folder",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724952432/GPTOrg%20Assets/EditFolderFileName_vekwnw.gif'
  },
  {
    title: 'Sharing - How Do I Share A Saved Response (Desktop Only)',
    description: "To add a collaborator to your saved file you can click on the 'Collaborators' link at the top of the saved document which will open a popup to see current collaborators. From here in the top right of the popup there is a button to add a collaborator, type in their email and desired permission and they will get an invite to be added to the file.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724951247/GPTOrg%20Assets/AddCollaboratorDemo_3_izb1do.gif'
  },
  {
    title: 'Sharing - How Do I Adjust Permissions? (Desktop Only)',
    description: "If you wish to change a collaborators permission, navigate to the desired document, click on the 'Collaborators' button, there you'll see a list of current collaborators and if you own the file you can use the dropdown next to their name to adjust their permission. Once you have your desired permission selected click on the 'Save' button to make the final adjustmet",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724952487/GPTOrg%20Assets/EditPermissionDemo_fbnb7z.gif'
  },
  {
    title: 'Sharing - Chat On A Document',
    description: "We are currently developing the ability for collaborators to chat on a document, stay up to date with development on our X channel.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724953404/GPTOrg%20Assets/shutterstock_2461301119_iyyycr.jpg'
  },
  {
    title: 'Sharing - Removing Collaborators (Desktop Only)',
    description: "To remove a collaborator, navigate to the desired document, click on the 'Collaborators' button after which you'll see the popup with current collaborators. If you are the file owner there will be a red button to the right of the collaborator name which you can click and remove the collaborator. Don't worry, this isn't permenent until you click the 'Save' button, so if you make a mistake simply click 'Cancle' and you can start fresh.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724952543/GPTOrg%20Assets/RemoveCollaboratorDemo_sln1gw.gif'
  },
  {
    title: 'A.I. Chat - How Do I Add More Credits For GPT 4o?',
    description: "We are currently developing our system for purchasing more credits. For now we are giving credits free, so please use our 'Support' form if you need more and we can add them for you.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724952059/GPTOrg%20Assets/AddCreditsDemo_adgivv.gif'
  },
  {
    title: 'A.I. Chat - How Can I See Past Conversations?',
    description: "On the A.I. chat page next to the input field where you type your messages you will see a little '+' button. Click on that for a popout menu that has the option to view chat history. From here you can see your conversation history and even resume the conversation. There is also the option to create a new chat in that popout window and if you do, the current conversation will be added to your chat history to resume later.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724952136/GPTOrg%20Assets/ChatHistoryDemo_wrzsdg.gif'
  },
  {
    title: 'A.I. Chat - How Do I See A Conversation Associated With A Saved Message?',
    description: "After navigating to the desired document, if you wish to resume the conversation from the point at which the response was saved, in the top right hand corner of the document there is a 'Magic' icon which you can click to resume the conversation.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724952198/GPTOrg%20Assets/ContinueConvoDemo_zxqy5r.gif'
  },
  {
    title: 'Profile Details - Access & Edit Profile',
    description: "In the sidebar click on the 'Profile' option or click on your username in the top navigation bar if logged in. Here you can add your email (only once, can not change right now), update your username, and change your subscription (coming soon).",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724953272/GPTOrg%20Assets/ViewEditProfile_xps9v9.gif'
  },
  {
    title: 'Analytics - How Do I View My Team Data?',
    description: "We are currently developing the ability to view team based analytics and onboard organizations, keep up to date with our X page for details and use the Support form to tell us what data points you'd like to be able to see so we can add them!",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724953404/GPTOrg%20Assets/shutterstock_2461301119_iyyycr.jpg'
  },
  {
    title: 'Admin - How Do I Control What Team Members Put Into A.I.?',
    description: "This is under development. Use our 'Support' form to tell us what kinds of controls you'd like to see for this and follow us on X to stay updated on our progress.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724953404/GPTOrg%20Assets/shutterstock_2461301119_iyyycr.jpg'
  },
  {
    title: 'Updates - How Do I Keep Up With New Releases & Features?',
    description: "Simply click on the 'X' icon to be taken to our X page where we continually update on our progress and new feature releases.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724952254/GPTOrg%20Assets/DevUpdatesDemo_a4sk9e.gif'
  },
  {
    title: 'Updates - How Do I Request New Features?',
    description: "Click on the 'Support' option in the sidebar to be taken to our support form. Type in your request to the form and submit, it is connected to our internal task platform so a team member will see it and update our X page if it is being added.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1724952593/GPTOrg%20Assets/RequestFeatureDemo_r5lqf8.gif'
  },
];

export const faqMobileItems = [
  {
    title: 'Saving - How Do I Save An AI Response?',
    description: "Every time the A.I. responds to a message you type in, there is a 'Save' button at the top of the response. Click on that to see a pop-out where you are able to name the file, add it to a folder and even create a new custom folder to store it in.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465748/GPTOrg%20Assets/MobileFAQGifs/SaveChatMobile-ezgif.com-crop_n3b7sj.gif'
  },
  {
    title: 'Saving - Where Do I See My Saved Responses?',
    description: "In the navigation sidebar there is a link called 'Folders' where you'll be directed to your collection of saved messages and can easily sort them, view them, then edit them.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465866/GPTOrg%20Assets/MobileFAQGifs/ViewFilesMobile-ezgif.com-crop_n9frsc.gif'
  },
  {
    title: 'Editing - How Do I Edit A Saved Response?',
    description: "To edit a saved response click on the 'Folders' link in the sidebar, click on the folder you wish to view or view all files by default, click on the message arrow to view the message and then in the top right of the message header (desktop only) you'll see an icon to 'Edit' the file. Make your edits then be sure to click the 'Save' icon in the top right.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465293/GPTOrg%20Assets/MobileFAQGifs/EditDocMobile-ezgif.com-crop_avi0bj.gif'
  },
  {
    title: 'Editing - Changing File Names & Folders',
    description: "After you've navigated to the 'Folders' page and clicked on the document arrow to view the document, you can click on the 'Edit' icon to open the editing view. Here in the top of the page you'll see a text field and dropdown allowing for renaming of the file and for moving of the folder",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465366/GPTOrg%20Assets/MobileFAQGifs/EditFolderAndNameMobile-ezgif.com-crop_wjhlcr.gif'
  },
  {
    title: 'Sharing - How Do I Share A Saved Response',
    description: "To add a collaborator to your saved file you can click on the 'Collaborators' link at the top of the saved document which will open a popup to see current collaborators. From here in the top right of the popup there is a button to add a collaborator, type in their email and desired permission and they will get an invite to be added to the file.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465808/GPTOrg%20Assets/MobileFAQGifs/ShareFileMobile-ezgif.com-crop_r8lbwp.gif'
  },
  {
    title: 'Sharing - How Do I Adjust Permissions?',
    description: "If you wish to change a collaborators permission, navigate to the desired document, click on the 'Collaborators' button, there you'll see a list of current collaborators and if you own the file you can use the dropdown next to their name to adjust their permission. Once you have your desired permission selected click on the 'Save' button to make the final adjustmet",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465431/GPTOrg%20Assets/MobileFAQGifs/EditPermissionMobile-ezgif.com-crop_dsky5u.gif'
  },
  {
    title: 'Sharing - Chat On A Document',
    description: "We are currently developing the ability for collaborators to chat on a document, stay up to date with development on our X channel.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725466763/GPTOrg%20Assets/MobileFAQGifs/mobilecomingsoontall_kj0xuo.png'
  },
  {
    title: 'Sharing - Removing Collaborators',
    description: "To remove a collaborator, navigate to the desired document, click on the 'Collaborators' button after which you'll see the popup with current collaborators. If you are the file owner there will be a red button to the right of the collaborator name which you can click and remove the collaborator. Don't worry, this isn't permenent until you click the 'Save' button, so if you make a mistake simply click 'Cancle' and you can start fresh.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465622/GPTOrg%20Assets/MobileFAQGifs/RemoveCollabMobile-ezgif.com-crop_nsxylz.gif'
  },
  {
    title: 'A.I. Chat - How Do I Add More Credits For GPT 4o?',
    description: "We are currently developing our system for purchasing more credits. For now we are giving credits free, so please use our 'Support' form if you need more and we can add them for you.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465559/GPTOrg%20Assets/MobileFAQGifs/ProfileInfoMobile-ezgif.com-video-to-gif-converter_eaumfx.gif'
  },
  {
    title: 'A.I. Chat - How Can I See Past Conversations?',
    description: "On the A.I. chat page next to the input field where you type your messages you will see a little '+' button. Click on that for a popout menu that has the option to view chat history. From here you can see your conversation history and even resume the conversation. There is also the option to create a new chat in that popout window and if you do, the current conversation will be added to your chat history to resume later.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465194/GPTOrg%20Assets/MobileFAQGifs/ChatHistoryMobile-ezgif.com-crop_y4wrgg.gif'
  },
  {
    title: 'A.I. Chat - How Do I See A Conversation Associated With A Saved Message?',
    description: "After navigating to the desired document, if you wish to resume the conversation from the point at which the response was saved, in the top right hand corner of the document there is a 'Magic' icon which you can click to resume the conversation.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465084/GPTOrg%20Assets/MobileFAQGifs/AssociatedConvoMobile-ezgif.com-crop_zqt9gh.gif'
  },
  {
    title: 'Profile Details - Access & Edit Profile',
    description: "In the sidebar click on the 'Profile' option or click on your username in the top navigation bar if logged in. Here you can add your email (only once, can not change right now), update your username, and change your subscription (coming soon).",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465559/GPTOrg%20Assets/MobileFAQGifs/ProfileInfoMobile-ezgif.com-video-to-gif-converter_eaumfx.gif'
  },
  {
    title: 'Analytics - How Do I View My Team Data?',
    description: "We are currently developing the ability to view team based analytics and onboard organizations, keep up to date with our X page for details and use the Support form to tell us what data points you'd like to be able to see so we can add them!",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725466763/GPTOrg%20Assets/MobileFAQGifs/mobilecomingsoontall_kj0xuo.png'
  },
  {
    title: 'Admin - How Do I Control What Team Members Put Into A.I.?',
    description: "This is under development. Use our 'Support' form to tell us what kinds of controls you'd like to see for this and follow us on X to stay updated on our progress.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725466763/GPTOrg%20Assets/MobileFAQGifs/mobilecomingsoontall_kj0xuo.png'
  },
  {
    title: 'Updates - How Do I Keep Up With New Releases & Features?',
    description: "Simply click on the 'X' icon to be taken to our X page where we continually update on our progress and new feature releases.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465521/GPTOrg%20Assets/MobileFAQGifs/KeepUpdatedMobile-ezgif.com-crop_yoqk6u.gif'
  },
  {
    title: 'Updates - How Do I Request New Features?',
    description: "Click on the 'Support' option in the sidebar to be taken to our support form. Type in your request to the form and submit, it is connected to our internal task platform so a team member will see it and update our X page if it is being added.",
    imgSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1725465680/GPTOrg%20Assets/MobileFAQGifs/RequestFeatureMobile-ezgif.com-crop_nxfyjl.gif'
  },
];