import React from 'react';
import GPTChat from '../../containers/GPTChat';
import ContentArea from '../../components/ContentArea';
import { DrawerHeader, Main } from '../../styled';


function AppHomeTrial({ drawerOpen, drawerWidth }) {
  
  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<GPTChat />} />
    </Main>
  );
}

export default AppHomeTrial;