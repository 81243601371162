import React from 'react'
import ContentArea from '../../components/ContentArea'
import AdminPanel from '../../containers/AdminPanel';
import { 
  // useDispatch, 
  useSelector 
} from 'react-redux';
import { DrawerHeader, Main } from '../../styled';

function Admin({ drawerOpen, drawerWidth }) {

  const allUserOrders = useSelector((state) => state?.adminData?.allUserOrders);

  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<AdminPanel ordersData={allUserOrders} />} />
    </Main>
  )
}

export default Admin