import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FolderIcon from '@mui/icons-material/Folder';
import XIcon from '@mui/icons-material/X';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

export const navigationOptions = [
  {id: 1, analyticId: 'click_ai_chat', name: "AI Chat", link: '/app-home', icon: <AutoAwesomeOutlinedIcon sx={{fontSize: '1.5rem', color: 'white'}} />}, 
  {id: 2, analyticId: 'click_folders', name: "Folders", link: '/folders', icon: <FolderOutlinedIcon sx={{fontSize: '1.5rem', color: 'white'}} />}, 
  {id: 3, analyticId: 'click_support', name: "Support", link: '/support', icon: <BugReportOutlinedIcon sx={{fontSize: '1.5rem', color: 'white'}} /> },
  {id: 3, analyticId: 'click_profile', name: "Profile", link: '/profile/info', icon: <AccountCircleOutlinedIcon sx={{fontSize: '1.5rem', color: 'white'}} /> },
  {id: 3, analyticId: 'click_faq', name: "FAQ", link: '/faq', icon: <HelpOutlineOutlinedIcon sx={{fontSize: '1.5rem', color: 'white'}} /> },
  {id: 4, analyticId: 'click_devUpdates', name: "Dev Updates", link: 'https://x.com/GptOrganized', icon: <XIcon sx={{fontSize: '1.5rem', color: 'white'}} /> }
];

export const trialNavigationOptions = [
  {id: 1, analyticId: 'click_trial_ai_chat', name: "AI Chat", link: '/', icon: <AutoAwesomeIcon sx={{fontSize: '1.5rem', color: 'white'}} />}, 
  {id: 2, analyticId: 'click_trial_folders', name: "Folders", link: '/folders-trial', icon: <FolderIcon sx={{fontSize: '1.5rem', color: 'white'}} />}, 
  {id: 3, analyticId: 'click_trial_faq', name: "FAQ", link: '/faq', icon: <HelpOutlineOutlinedIcon sx={{fontSize: '1.5rem', color: 'white'}} /> },
  {id: 4, analyticId: 'click_trial_devUpdates', name: "Dev Updates", link: 'https://x.com/GptOrganized', icon: <XIcon sx={{fontSize: '1.5rem', color: 'white'}} /> }
];
