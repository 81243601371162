// MarkdownRenderer.js
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CodeBlockContainer } from './styled';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

const MarkdownRenderer = ({ content }) => {
  
  useEffect(() => {
    // Load Prism.js dynamically for highlighting
    import('prismjs/themes/prism-okaidia.css');
  }, []);

  const codeBlockStyle = {
    fontSize: '14px', 
    lineHeight: '1.5',
    width: '100%',
    margin: '0',
  };

  return (
    <ReactMarkdown
      children={content}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <CodeBlockContainer container>
              <SyntaxHighlighter
                style={okaidia}
                language={match[1]}
                PreTag="div"
                {...props}
                customStyle={codeBlockStyle}
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            </CodeBlockContainer>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    />
  );
};

export default MarkdownRenderer;