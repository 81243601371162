import React, { useState } from 'react';
import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ContainerParentGrid, 
  DocumentListGrid, 
  InnerGridBackgroundContainer
} from './styled';
import { Typography, useMediaQuery } from '@mui/material';
import { faqItems, faqMobileItems } from './faqItems';
import FAQItem from './FAQItem';

function FAQs() {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const ResponsiveFAQs = () => {
    if(!isMobile){
      return (
        faqItems.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              aria-controls={`panel${index + 1}d-content`}
              id={`panel${index + 1}d-header`}
            >
              <Typography>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FAQItem faqItemData={item} isMobile={isMobile} />
            </AccordionDetails>
          </Accordion>
        ))
      )
    } else {
      return (
        faqMobileItems.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              aria-controls={`panel${index + 1}d-content`}
              id={`panel${index + 1}d-header`}
            >
              <Typography>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FAQItem faqItemData={item} isMobile={isMobile} />
            </AccordionDetails>
          </Accordion>
        ))
      )
    }
  }

  return (
    <ContainerParentGrid>
      <DocumentListGrid container>
        <InnerGridBackgroundContainer>
          <ResponsiveFAQs />
        </InnerGridBackgroundContainer>
      </DocumentListGrid>
    </ContainerParentGrid>
  )
};

export default FAQs;