import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../actions/userActions';
import { dbSavedMessages } from '../../actions/gptChatActions';

const InviteHandler = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { 
    isAuthenticated, 
    loginWithRedirect, 
    getAccessTokenSilently 
  } = useAuth0();

  const userInfo = useSelector((state) => state?.userData?.loginInfo);

  useEffect(() => {
    const processInvite = async () => {
      const queryParams = new URLSearchParams(location.search);
      const messageId   = queryParams.get('messageId');
      const permission  = queryParams.get('permission');
      const inviteId  = queryParams.get('inviteId');

      if (!isAuthenticated) {
        loginWithRedirect({
          appState: { returnTo: `/invite?messageId=${messageId}&permission=${permission}&inviteId=${inviteId}` }
        });
      } else {

        if (userInfo?.user_id === undefined) {
          return;
        };

        try {

          // Make an API call to process the invite
          const response = await axios.post(
            `${process.env.REACT_APP_API_PATH}api/messages/acceptInvite`, 
            {
              messageId: messageId,
              permission: permission,
              userId: userInfo?.user_id,
              inviteId: inviteId
            },
            config
          );

          if(response?.status === 200){
            dispatch(
              dbSavedMessages(
                {
                  userId: userInfo?.user_id, 
                  invitedMessage: response?.data?.message
                }, 
                dispatch
              )
            );

            navigate(`/folders`);
          } else {
            navigate('/app-home');
          };

          // Redirect to a page after processing the invite
        } catch (error) {

          navigate('/app-home')
          console.error('Error processing invite:', error);
        };
      }
    };

    processInvite();

    // eslint-disable-next-line
  }, [
    isAuthenticated, 
    loginWithRedirect, 
    getAccessTokenSilently, 
    location.search,
    userInfo?.user_id
  ]);

  return (
    <Grid 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: '-webkit-fill-available', 
        height: '-webkit-fill-available'
      }}
    >
      <Typography variant='h5' sx={{color: 'white', marginBottom: '1rem'}}>
        Processing Invite...
      </Typography>
      <CircularProgress sx={{width: '3rem', height: '3rem', color: '#84A8FF'}}/>
    </Grid>
  );
};

export default InviteHandler;