export const MESSAGES_COLLECTION_SAVE_SUCCESS = 'MESSAGES_COLLECTION_SAVE_SUCCESS';
export const GPT_VERSION_CHANGE = 'GPT_VERSION_CHANGE';
export const SAVE_TRIAL_GPT_MESSAGE_SUCCESS = 'SAVE_TRIAL_GPT_MESSAGE_SUCCESS';
export const UPDATE_TRIAL_GPT_MESSAGE_SUCCESS = 'UPDATE_TRIAL_GPT_MESSAGE_SUCCESS';
export const CLEAR_GLOBAL_MESSAGES = 'CLEAR_GLOBAL_MESSAGES';

export const GET_DB_MESSAGES_REQUEST = 'GET_DB_MESSAGES_REQUEST';
export const GET_DB_MESSAGES_SUCCESS = 'GET_DB_MESSAGES_SUCCESS';
export const GET_DB_MESSAGES_FAIL = 'GET_DB_MESSAGES_FAIL';

export const SEND_COLLAB_INVITE_REQUEST = 'SEND_COLLAB_INVITE_REQUEST';
export const SEND_COLLAB_INVITE_SUCCESS = 'SEND_COLLAB_INVITE_SUCCESS';
export const SEND_COLLAB_INVITE_ERROR = 'SEND_COLLAB_INVITE_ERROR';

export const SAVE_GPT_MESSAGE_FAIL = 'SAVE_GPT_MESSAGE_FAIL';
export const SAVE_GPT_MESSAGE_REQUEST = 'SAVE_GPT_MESSAGE_REQUEST';
export const SAVE_GPT_MESSAGE_SUCCESS = 'SAVE_GPT_MESSAGE_SUCCESS';

export const DELETE_GPT_MESSAGE_REQUEST = 'DELETE_GPT_MESSAGE_REQUEST';
export const DELETE_GPT_MESSAGE_SUCCESS = 'DELETE_GPT_MESSAGE_SUCCESS';
export const DELETE_GPT_MESSAGE_FAIL    = 'DELETE_GPT_MESSAGE_FAIL'   ;

export const DELETE_GPT_FOLDER_REQUEST = 'DELETE_GPT_FOLDER_REQUEST';
export const DELETE_GPT_FOLDER_SUCCESS = 'DELETE_GPT_FOLDER_SUCCESS';
export const DELETE_GPT_FOLDER_FAIL    = 'DELETE_GPT_FOLDER_FAIL'   ;

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAIL = 'UPDATE_DOCUMENT_FAIL';