import React from 'react'
import { useTheme } from '@emotion/react';
import { 
  Button, 
  Card, 
  Grid, 
  Typography, 
  useMediaQuery 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import gtmTrackButtonClick from '../../utils/gtmTrackButtonClick';

function StartTourPopup({ 
  videoDemoLaunchOpen, 
  setVideoDemoLaunchOpen,
  setStartVideo
}) {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const theme = useTheme();
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  return (
    <Grid
        sx={{
          display: !videoDemoLaunchOpen && 'none',
          position: 'absolute',
          top: isMobile ? 300 : 15,
          right: isMobile ? 20 : 50,
        }}
      >
        <Card
          elevation={5}
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: 'column',
            padding: isMobile ? '1rem' : '1rem', 
            zIndex: 1000, 
            width: isMobile ? '90dvw' : '30rem',
            height: isMobile ? '10rem' : '12rem',
            position: 'relative',
            border: `0.15rem solid ${theme.palette.secondary.main}`,
            borderRadius: '1rem'
          }}
        >
          <Typography variant='h5' sx={{textAlign: 'center', padding: '0.5rem'}}>
            First time?<br /> For All Features Sign Up!
          </Typography>
          <Grid>
            <Button
              variant='outlined' 
              sx={{marginRight: '1rem', color: 'gray', borderColor: 'lightgray'}}
              onClick={() => setVideoDemoLaunchOpen(false)}
            >
              <Typography>
                No Thanks
              </Typography>
            </Button>
            <Button
              variant='contained'
              onClick={(e) => {
                gtmTrackButtonClick(e, 'click_register_desktop_popup');
                loginWithRedirect({
                  authorizationParams: { screen_hint: "signup" }
                })
              }}
              sx={{
                color: 'white',
                background: `linear-gradient(to left, #507CE6 0%, #84A8FF 100%)` 
              }}
            >
              Register Free
            </Button>
          </Grid>
        </Card>
      </Grid>
  )
}

export default StartTourPopup;