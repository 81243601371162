import React from 'react'
import { 
  Grid, 
  Typography, 
} from '@mui/material';

function FAQItem({ faqItemData, isMobile }) {

  return (
    <Grid 
      container 
      sx={{ 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row',
      }}
    >
      <Grid 
        item 
        sx={{ 
          display: 'flex', 
          flex: '1', 
          padding: '1rem',
          width: isMobile && '100%'
        }}
      >
        <Typography>{faqItemData.description}</Typography>
      </Grid>
      {
        faqItemData.imgSrc && (
          <Grid 
            item 
            sx={{ 
              display: 'flex', 
              flex: '1', 
              padding: '1rem', 
              justifyContent: isMobile ? 'center' : 'flex-end'
            }}
          >
            <img 
              src={faqItemData.imgSrc} 
              alt={faqItemData.title} 
              height={ isMobile ? '450rem' : '250rem' }
              width={ isMobile && '215rem' }
            />
          </Grid>
        )
      }

    </Grid>
  )
}

export default FAQItem;