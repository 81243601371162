import React, { useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Chip, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { updateGPTVersion } from '../../actions/gptChatActions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth0 } from '@auth0/auth0-react';

const options = ['GPT 3.5', 'GPT 4o'];

export default function ModelVersionSelect() {
  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const userGenCredits = useSelector((state) => state?.userData?.loginInfo?.userCredits);
  const selectedVersion = useSelector((state) => state?.gptChat?.gptVersion);
  const previouslySelectedVersion = localStorage.getItem('AIVersion')

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(() => {
    if(selectedVersion){
     return  options.indexOf(selectedVersion);
    };

    if(previouslySelectedVersion){
      return  options.indexOf(previouslySelectedVersion);
    };

    return 0;
  });

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    dispatch(updateGPTVersion(options[index], dispatch));
    localStorage.setItem('AIVersion', options[index]);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useMemo(() => {
    if(userGenCredits < 6){
      setSelectedIndex(0)
    }
  }, [userGenCredits]);

  return (
    <Grid 
      sx={{
        display: 'flex', 
        borderRadius: '1rem', 
        padding: '0.25rem',
        backgroundColor: '#EEF2FC',
        width: '15rem', 
        height: '3rem',
        justifyContent: 'space-between'
      }}
    >
      <ButtonGroup
        variant="text"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        sx={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'space-between', width: '100%'}}
      >
        <Button onClick={handleToggle} sx={{borderRight: 'none!important'}}>
          <Typography variant='h7' sx={{color: 'black'}}>
            {options[selectedIndex]}
          </Typography>
        </Button>
        <Button
          size="md"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          sx={{borderLeft: 'none'}}
          onClick={handleToggle}
        >
          {
            selectedIndex === 0 ? (
              <AllInclusiveIcon 
                sx={{
                  fontSize: '1rem', 
                  color: 'black', 
                  margin: '0 0.5rem'
                }} 
              />
            ) : (
              <Chip 
                variant='outlined' 
                label={`Credits: ${userGenCredits}`}  
                sx={{ 
                  color: 'black', 
                  marginRight: '0.5rem', 
                  paddingTop: '0'
                }}
              />
            )
          }
          <KeyboardArrowDownIcon sx={{fontSize: '1rem', color: 'black'}}/>
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{width: '15rem'}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem sx={{width: '100%'}}>
                  {options?.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      sx={{width: '100%', display: 'flex'}}
                    >
                      {
                        !isAuthenticated && option === 'GPT 4o' ? (
                          <Grid 
                            sx={{
                              display: 'flex', 
                              justifyContent: 'space-between',
                              width: '100%'
                            }}
                            onClick={() => loginWithRedirect()}
                          >
                            <Typography variant='h6'>
                              {option}
                            </Typography>
                            <Chip 
                              color='secondary'
                              label={'Log In To Use'}
                            />
                          </Grid>
                        ) : (
                          <Typography variant='h6'>
                            {option}
                          </Typography>
                        )
                      }
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}