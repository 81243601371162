import React from 'react';
import ContentArea from '../../components/ContentArea';
import InviteHandler from '../../components/InviteHandler';
import { DrawerHeader, Main } from '../../styled';


function InviteScreen({ drawerOpen, drawerWidth }) {
  
  return (
    <Main open={drawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader />
      <ContentArea childComponent={<InviteHandler />} />
    </Main>
  );
}

export default InviteScreen;