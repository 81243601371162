import React, { useState } from 'react'
import SaveToFolderModule from './SaveToFolderModule';
import AddNewFolderModule from './AddNewFolderModule';

function SavePopout({ 
  addNewFolder,
  handleClose,
  userFolders,
  setAddNewFolder,
  setUserFolders,
  setSelectedFolder,
  selectedFolder,
  handleSaveDoc,
  handleChange
}) {

  const [selectedDocName, setSelectedDocName] = useState(null);
  const [newFolderName, setNewFolderName] = useState(null);

  const RenderPopout = () => {
    if(addNewFolder){
      return (
        <AddNewFolderModule 
          handleClose={handleClose}
          userFolders={userFolders}
          setAddNewFolder={setAddNewFolder}
          setUserFolders={setUserFolders}
          setSelectedFolder={setSelectedFolder}
          newFolderName={newFolderName}
          setNewFolderName={setNewFolderName}
        />
      )
    } else {
      return (
        <SaveToFolderModule 
          selectedFolder={selectedFolder}
          handleSaveDoc={handleSaveDoc}
          handleClose={handleClose}
          handleChange={handleChange}
          userFolders={userFolders}
          setAddNewFolder={setAddNewFolder}
          selectedDocName={selectedDocName}
          setSelectedDocName={setSelectedDocName}
        />
      )
    }
  }

  return RenderPopout();
}

export default SavePopout;