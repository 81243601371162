const getUserFilesNumber = (messages) => {
  const uniqueFolders = new Set();  // Create a Set to keep only unique values
  const filteredFolders = 
    messages?.map(message => message.folder)
      ?.filter(folder => {
        const isNew = !uniqueFolders.has(folder);
        if (isNew) uniqueFolders.add(folder);
        return isNew;
      }
    );
  
  const numFiles = filteredFolders?.length;

  return numFiles;
};

export default getUserFilesNumber;