import React from 'react'
import { 
  FolderIconGrid, 
  FolderNameGrid, 
  InnerGridBackgroundContainer, 
  ParentGrid 
} from './styled';
import { Typography } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';

function FolderListItem({
  folder,
  shared,
  setSelectedFolder,
  setManagerView,
  selectedFolder
}) {

  const isSelected = (folder === selectedFolder);
  const handleSelectFolder = () => {
    setManagerView('Files');
    setSelectedFolder(folder);
  };

  return (
    <ParentGrid container onClick={handleSelectFolder}>
      <InnerGridBackgroundContainer container isSelected={isSelected}>
        <FolderIconGrid item>
          {
            shared ? (
              <FolderSharedOutlinedIcon sx={{color: isSelected ? '#507CE6' : 'gray', fontSize: '2rem'}} />
            ) : (
              <FolderOutlinedIcon sx={{color: isSelected ? '#507CE6' : 'gray', fontSize: '2rem'}} />
            )
          }
        </FolderIconGrid>
        <FolderNameGrid item>
          <Typography variant='h5' sx={{color: isSelected ? '#507CE6' : 'gray'}}>
            {folder}
          </Typography>
        </FolderNameGrid>
      </InnerGridBackgroundContainer>
    </ParentGrid>
  )
}

export default FolderListItem;