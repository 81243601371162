import { Grid, styled } from "@mui/material";


const ParentGrid = styled(Grid)(({theme}) => ({
  display: 'flex',
  width: '100%',
  height: '5rem',
  padding: '0.5rem',
  '& :hover': {
    cursor: 'pointer',
    // backgroundColor: `${theme.palette.secondary.main}`,
    color: '#507CE6'
  }
}));

const InnerGridBackgroundContainer = styled(Grid)(({theme, isSelected}) => ({
  width: '100%', 
  height: '100%', 
  backgroundColor: isSelected && `${theme.palette.secondary.main}`,
  borderRadius: '3rem',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'nowrap',
}));

const FolderIconGrid = styled(Grid)(({theme}) => ({
  flex: '1',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '1rem'
}));

const FolderNameGrid = styled(Grid)(({theme}) => ({
  flex: '3',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: '1rem'
}));

const FolderEditGrid = styled(Grid)(({theme}) => ({
  flex: '1',
  height: '100%'
}));

export {
  ParentGrid,
  InnerGridBackgroundContainer,
  FolderIconGrid,
  FolderNameGrid,
  FolderEditGrid
}