import { DELETE_CHAT_REQUEST, DELETE_CHAT_SUCCESS, REMOVE_COLLABORATOR_SUCCESS, REMOVE_INVITE_SUCCESS, SEND_CHAT_REQUEST, SEND_CHAT_SUCCESS, UPDATE_COLLABORATORS_SUCCESS } from "../consts/collaboratorsConstants";
import { 
  CLEAR_GLOBAL_MESSAGES, 
  DELETE_GPT_FOLDER_FAIL, 
  DELETE_GPT_FOLDER_REQUEST, 
  DELETE_GPT_FOLDER_SUCCESS, 
  DELETE_GPT_MESSAGE_FAIL, 
  DELETE_GPT_MESSAGE_REQUEST, 
  DELETE_GPT_MESSAGE_SUCCESS, 
  GET_DB_MESSAGES_FAIL, 
  GET_DB_MESSAGES_REQUEST, 
  GET_DB_MESSAGES_SUCCESS, 
  GPT_VERSION_CHANGE, 
  MESSAGES_COLLECTION_SAVE_SUCCESS, 
  SAVE_GPT_MESSAGE_FAIL, 
  SAVE_GPT_MESSAGE_REQUEST, 
  SAVE_GPT_MESSAGE_SUCCESS, 
  SAVE_TRIAL_GPT_MESSAGE_SUCCESS, 
  SEND_COLLAB_INVITE_SUCCESS, 
  UPDATE_DOCUMENT_FAIL, 
  UPDATE_DOCUMENT_REQUEST, 
  UPDATE_DOCUMENT_SUCCESS, 
  UPDATE_TRIAL_GPT_MESSAGE_SUCCESS 
} from "../consts/gptChatConstants";


export const gptChatReducer = (state = {}, action) => {

  switch(action.type){
    case MESSAGES_COLLECTION_SAVE_SUCCESS: 
      return { 
        ...state,
        messages: action.payload 
      };
    case GPT_VERSION_CHANGE:
      return {
        ...state,
        gptVersion: action.payload
      };
    case SAVE_TRIAL_GPT_MESSAGE_SUCCESS:
      return{
        ...state,
        trialMessageSaves: [
          ...state.trialMessageSaves,
          action.payload
        ]
      };
    case UPDATE_TRIAL_GPT_MESSAGE_SUCCESS:
      return{
        ...state,
        trialMessageSaves: state.trialMessageSaves.map(message =>
          message._id === action.payload._id ? action.payload : message
        )
      };
    case CLEAR_GLOBAL_MESSAGES:   
      return {
        ...state,
        messages: []
      };
    case GET_DB_MESSAGES_REQUEST: 
      return {
        ...state,
        dbMessagesLoading: true,
        dbMessagesFetchError: null
      };
    case GET_DB_MESSAGES_SUCCESS:
      return {
        ...state,
        ownedDbMessages: action.payload.ownedMessages ?? [],
        externalDbMessages: action.payload.externalMessages ?? [],
        dbMessagesLoading: false
      };
    case GET_DB_MESSAGES_FAIL:
      return {
        ...state,
        dbMessagesLoading: false,
        dbMessagesFetchError: action.payload
      }
    case UPDATE_COLLABORATORS_SUCCESS:
      return {
        ...state,
        ownedDbMessages: state.ownedDbMessages?.map(message =>
          message._id === action.payload.messageId ? 
          { ...message, sharedWith: action.payload.sharedWith } : 
          message
        )
      };
    case REMOVE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        ownedDbMessages: state.ownedDbMessages?.map(message =>
          message._id === action.payload.messageId ? 
          { ...message, sharedWith: action.payload.sharedWith } : 
          message
        )
      };
    case SEND_COLLAB_INVITE_SUCCESS:
      return {
        ...state,
        ownedDbMessages: state.ownedDbMessages.map(message => 
          message._id === action.payload._id ? action.payload : message
        )
      };
    case REMOVE_INVITE_SUCCESS: 
      return {
        ...state,
        ownedDbMessages: state.ownedDbMessages?.map(message =>
          message._id === action.payload.updatedMessage._id 
            ? { ...message, invites: action.payload.updatedMessage.invites } 
            : message
        )
      };
    case SAVE_GPT_MESSAGE_REQUEST:
      return {
        ...state,
        savingGptMessage: true
      };
    case SAVE_GPT_MESSAGE_SUCCESS:
      return {
        ...state,
        savingGptMessage: false,
        ownedDbMessages: [...state.ownedDbMessages, action.payload]
      };
    case SAVE_GPT_MESSAGE_FAIL:
      return {
        ...state,
        savingGptMessage: false,
        gptMessageSaveError: action.payload.error
      };
    case DELETE_GPT_MESSAGE_REQUEST:
      return {
        ...state,
        deleteMessageLoading: true
      };
    case DELETE_GPT_MESSAGE_SUCCESS:
      return {
        ...state,
        ownedDbMessages: state.ownedDbMessages?.filter(message => message._id !== action.payload.messageId),
        deleteMessageLoading: false
      };
    case DELETE_GPT_MESSAGE_FAIL:
      return {
        ...state,
        deleteMessageError: action.payload.error,
        deleteMessageLoading: false
      };
    case DELETE_GPT_FOLDER_REQUEST:
      return {
        ...state,
        deleteFolderLoading: true
      };
    case DELETE_GPT_FOLDER_SUCCESS:
      return {
        ...state,
        ownedDbMessages: state.ownedDbMessages?.filter(
          message => message.folder !== action.payload.folderName
        ),
        deleteFolderLoading: false
      };
    case DELETE_GPT_FOLDER_FAIL:
      return {
        ...state,
        deleteFolderError: action.payload.error,
        deleteFolderLoading: false
      };
    case UPDATE_DOCUMENT_REQUEST:
      return {
        ...state,
        updatingDocument: true,
        updateDocumentError: {}
      };
    case UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        ownedDbMessages: state.ownedDbMessages.map(message =>
          message._id === action.payload._id ? action.payload : message
        ),
        updatingMessage: false
      };
    case UPDATE_DOCUMENT_FAIL:
      return {
        updatingDocument: false,
        updateDocumentError: action.payload.error
      };
    case SEND_CHAT_REQUEST:
      return {
        ...state,
        collabChatUpdating: true
      };
    case SEND_CHAT_SUCCESS:

      const updatedMessage = action.payload;

      const updateMessages = (messages) => (
        messages?.map(msg =>
          msg._id === updatedMessage._id ? updatedMessage : msg
        )
      );

      return {
        ...state,
        ownedDbMessages: updateMessages(state.ownedDbMessages),
        externalDbMessages: updateMessages(state.externalDbMessages),
        collabChatUpdating: false
      };
    case DELETE_CHAT_REQUEST: {
      return {
        ...state,
        chatDeleting: true,
        deletingChatId: action.payload
      }
    }
    case DELETE_CHAT_SUCCESS: {

      const { documentId, chatId } = action.payload;

      const removeChatFromMessages = (messages) => {
        return messages.map(doc => {
          if (doc._id === documentId) {
            return {
              ...doc,
              collabChats: doc.collabChats.filter(chat => chat._id !== chatId)
            };
          };

          return doc;
        });
      };

      return {
        ...state,
        ownedDbMessages: removeChatFromMessages(state.ownedDbMessages),
        externalDbMessages: removeChatFromMessages(state.externalDbMessages),
        chatDeleting: false,
        deletingChatId: null
      };
    };
    default: return {
      ...state
    };
  };
};