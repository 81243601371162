import { FormControl, Grid, InputLabel, MenuItem } from '@mui/material';
import React from 'react'
import { CustomTextField, FolderSelect } from './styled';

function EditDocDetails({
  documentFolder,
  setDocumentFolder,
  uniqueFolders,
  userIsOwner,
  documentName,
  setDocumentName,
  isMobile
}) {
  return (
    <Grid 
      sx={{ 
        maxHeight: '6rem', 
        width: '100%', 
        display: 'flex', 
        alignItems: 'center'
      }}
    >
      <FormControl sx={{ m: 1, minWidth: 150, color: 'black' }}>
        <InputLabel id="select-folder-label" sx={{color: 'black'}}>
          Folder
        </InputLabel>
        <FolderSelect
          labelId="select-folder-label"
          id="select-folder"
          sx={{color: 'black', borderColor: 'black'}}
          value={documentFolder}
          label="Folder"
          disabled={!userIsOwner()}
          onChange={(e) => setDocumentFolder(e.target.value)}
        >
          {
            uniqueFolders?.map((folder, index) => (
              <MenuItem key={index} value={folder}>
                {folder}
              </MenuItem>
            ))
          }
        </FolderSelect>
      </FormControl>
      <CustomTextField
        multiline
        rows={1}
        value={documentName}
        sx={{
          color: 'black', 
          flex: '2',
          display: 'flex', 
          width: '100%',
          height: '100%',
          marginRight: '1rem',
        }}
        onChange={(e) => setDocumentName(e.target.value)}
        disabled={!userIsOwner()}
      />
    </Grid>
  )
}

export default EditDocDetails;