import { 
  Routes, 
  Route, 
  Outlet, 
  Navigate,
} from 'react-router-dom';
import { AppContainer } from './styled';
import Profile from './registeredViews/Profile';
import { useSelector } from 'react-redux';
import AppNavigation from './components/AppNavigation';
import { CircularProgress, CssBaseline, Grid, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import Support from './registeredViews/Support';
import Admin from './registeredViews/Admin';
import AppHome from './registeredViews/AppHome';
import Folders from './registeredViews/Folders';
import ChatHistoryList from './registeredViews/ChatHistoryList';
import FoldersTrial from './unregisteredViews/FoldersTrial';
import AppHomeTrial from './unregisteredViews/AppHomeTrial';
import InviteScreen from './unregisteredViews/InviteScreen';
import FAQPage from './unregisteredViews/FAQ';
// import LiveDemo from './unregisteredViews/LiveDemo';
// import Landing from './unregisteredViews/Landing';

function App() {

	const drawerWidth = 240;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [authCheckCompleted, setAuthCheckCompleted] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(isMobile ? false : true);

  const userInfo = useSelector((state) => state?.userData?.loginInfo);

  console.log(
    '%c Go away, nothing to see here ;)', 
    'background: #66a18c; color: white; font-size: 20px;'
  );

  const PrivateRoutes = () => {
    
    if (!authCheckCompleted) {
      // Show a loading indicator while authentication check is ongoing
      return (
        <Grid 
          sx={{
            height: '100dvh', 
            width: '90dvw', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center'
          }}
        >
          <CircularProgress 
            sx={{ 
              height: '5rem', 
              width: '5rem',
              color: '#84A8FF'
            }}
          />
        </Grid>
      );
    };

    if (userInfo?.user_id) {
      return <Outlet />;
    } else {
      return <Navigate to="/" />;
    };
  };

  useEffect(() => {
    if (userInfo !== undefined) {
      setAuthCheckCompleted(true);
    } else {
      setAuthCheckCompleted(false);
    };

  }, [userInfo]);
  
  return (
    <>
      <AppContainer>
			  <CssBaseline />
        <AppNavigation 
          setDrawerOpen={setDrawerOpen} 
          drawerOpen={drawerOpen} 
          drawerWidth={drawerWidth}
        />
        <Routes>
          <Route path = "/invite"             element = { <InviteScreen drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
          <Route path = "/"                   element = { <AppHomeTrial drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
          <Route path = "/folders-trial"      element = { <FoldersTrial drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
          <Route path = "/folders-trial/:id"  element = { <FoldersTrial drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
          <Route path = "/faq"                element = { <FAQPage      drawerOpen={drawerOpen} drawerWidth={drawerWidth} />  } />
          {/* <Route path = "/demo"               element = { <LiveDemo />     } /> */}

          <Route element  =  { <PrivateRoutes /> }>
            <Route path = "/support"          element = { <Support         drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />
            <Route path = "/profile/:slug"    element = { <Profile         drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />
            <Route path = "/app-home"         element = { <AppHome         drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
            <Route path = "/admin"            element = { <Admin           drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
            <Route path = "/folders"          element = { <Folders         drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
            <Route path = "/folders/:id"      element = { <Folders         drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
            <Route path = "/chat-history"     element = { <ChatHistoryList drawerOpen={drawerOpen} drawerWidth={drawerWidth} /> } />            
          </Route>        
        </Routes>
      </AppContainer>
    </>
  );
};

export default App;
